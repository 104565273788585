@import url('https://fonts.googleapis.com/css2?family=Amarante&display=swap');

$color_primary: #37ac88;
$color_secondary: #62292c;
$color_tertiary: #ed3833;
$color_quartiary: #dca87f;

@mixin set-font-style {
    font-family: 'Amarante', cursive !important;
}

@mixin set-text-shadow {
    text-shadow: 0 0 2px $color_quartiary !important;
}

#header {
    background-color: $color_primary !important;
    padding: 4px 0 !important;
    border-bottom: 8px solid #fff !important;
    .logo img {
        filter: drop-shadow(2px 4px 6px black);
        width: 180px !important;
        max-width: 180px !important;
        max-height: 110px !important;
        @media only screen and (max-width: 991px) {
            max-width: 128px !important;
            max-height: 86px !important;
        }
    }
}

.section-title h2 {
    @include set-font-style;
    color: $color_primary !important;
    text-shadow: 0 0 2px #cecece !important;
}

.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: $color_secondary !important;
}

.navbar a, .navbar a:focus {
    color: #fff !important;
    font-size: 24px !important;
    @media only screen and (max-width: 991px) {
        color: $color_primary !important;
    }
}

.back-to-top {
    background: $color_primary !important;
}

#footer .footer-top .social-links a {
    background: $color_primary !important;
}

.gallery-item, .portfolio-wrap {
    .img-fluid {
        width: 100% !important;
        height: 300px !important;
        @media only screen and (max-width: 991px) {
            width: 100% !important;
            height: 200px !important;
        }
    }
}

.hero-title, .nav-link {
    @include set-font-style;
    text-shadow: 0 0 2px #cecece !important;
}

.hero-title {
    color: #fff !important;
    text-shadow: 0 0 16px $color_secondary !important;
}

.hero-img .img-fluid {
    width: 70% !important;
}

#hero {
    background: url('./../public/assets/img/title-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}